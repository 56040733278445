<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img
                    class="img-fluid for-light"
                    src="../assets/images/logo/running-logo-login.png"
                    alt="looginpage"
                  />
                  <img
                    class="img-fluid for-dark"
                    src="../assets/images/logo/logo_dark.png"
                    alt="looginpage"
                  />
                </a>
              </div>
              <div class="login-main">
                <form class="theme-form" @submit.prevent="register">
                  <h4>Create your account</h4>
                  <p>Enter your personal details to create account</p>
                  <div class="form-group">
                    <label class="col-form-label pt-0">Your Name</label>
                    <div class="row g-2">
                      <div class="col-6">
                        <input
                          class="form-control"
                          type="text"
                          required=""
                          v-model="name"
                          placeholder="First name"
                        />
                      </div>
                      <div class="col-6">
                        <input
                          class="form-control"
                          type="text"
                          required=""
                          v-model="sername"
                          placeholder="Last name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Page Name</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="display_name"
                      required=""
                      placeholder="RUNNING"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Mobile No</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="mobile_no"
                      required=""
                      placeholder="090xxx"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input
                      class="form-control"
                      type="email"
                      v-model="email"
                      required=""
                      placeholder="info@gmail.com"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input
                        class="form-control"
                        :type="active ? 'password' : 'text'"
                        name="login[password]"
                        required=""
                        v-model="password"
                        placeholder="*********"
                      />
                      <div class="show-hide">
                        <span
                          :class="active ? 'show' : 'hide'"
                          @click.prevent="show"
                        >
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox" />
                      <label class="text-muted" for="checkbox1"
                        >Agree with<a class="ms-2" href="#"
                          >Privacy Policy</a
                        ></label
                      >
                    </div>
                    <button type="submit" class="btn btn-primary btn-block">
                      <vue-feather type="loader" size="18" animation="spin" v-if="this.is_loading ==='Y'"></vue-feather> Create Account
                    </button>
                  </div>

                  <p class="mt-4 mb-0">
                    Already have an account?
                    <router-link class="ms-2" to="/auth/login">
                      Login
                    </router-link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      active: true,
      name: "",
      sername: "",
      email: "",
      password: "",
      display_name: "",
      mobile_no: "",
      is_loading: ""
    };
  },
  methods: {
    show() {
      this.active = !this.active;
    },
    async register() {
      this.is_loading = "Y"
      try {
        const data = {
          name: this.name,
          sername: this.sername,
          email: this.email,
          password: this.password,
          display_name: this.display_name,
          mobile_no: this.mobile_no
        };
        //console.log("register",data)

        const response = await axios.post("/v1/user/register", data);

        if (response.data.status === "success") {
          alert("ลงทะเบียนสำเร็จ, please login");
          this.$router.push("/auth/login");
        }else{
          alert("Register error, มีการใช้งาน email นี้ในระบบแล้ว กรุณาแจ้งแอดมิน เพื่อ reset รหัสผ่าน หรือใช้ Email อื่น "+response.data.message);
        }
        this.is_loading = "N"
      } catch (error) {
        conso
        alert("Register error, please contact administrator", error.message);
        //this.$router.push({ path: "/auth/login" });
      }
    },
  },
};
</script>
