<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img
                    width="300"
                    class="img-fluid for-light"
                    src="../assets/images/logo/running-logo-login.png"
                    alt="looginpage"
                  />
                  <img
                    width="300"
                    class="img-fluid for-dark"
                    src="../assets/images/logo/logo_dark.png"
                    alt="looginpage"
                  />
                </a>
              </div>
              <div class="login-main">
                <form class="theme-form" @submit.prevent="reset_password">
                  <h4>Reset Your Password</h4>
                  <p>Enter your email for reset password</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input
                      class="form-control"
                      type="email"
                      required=""
                      placeholder="your@gmail.com"
                      v-model="email"
                    />
                    </div>
                  <div class="form-group mb-0">
                    <div class="text-end mt-3">
                      <button
                        class="btn btn-primary btn-block w-100"
                        type="submit"
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                  <h6 class="text-muted mt-4"></h6>

                  <p class="mt-4 mb-0 text-center">
                    <a class="ms-2" href="/auth/login">เข้าสู่ระบบ</a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.validate-error {
  color: red;
}
</style>

<script>
import axios from "axios";
export default {
  name: "forgot_password",
  setup() {

  },
  data() {
    return {
      email: "",
    };
  },

  created() {
    //this.user_store.clear();
  },

  methods: {
    async reset_password() {
      try {

        if(!confirm('ต้องการ Reset password ของ email '+this.email+'?')){
          return false;
        }
        let data = {
          email: this.email
        };

        const response = await axios.post("/v1/auth/reset-password", data);

        console.log("response", response)

        //localStorage.setItem("_rr_user", JSON.stringify(response.data.body));
        if (response.data.status === "success") {
          alert(response.data.message)
        } else {
         
          alert("Login error, invalid credentials");
        }
      } catch (error) {
        alert("พบข้อผิดพลาด! กรุณา login อีกครั้ง");
      
      }
    },
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>
