<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col" v-if="this.is_event_permission === 'Y'">
        <div class="row">
          <!-- <WelcomeCard /> -->
          <div class="col-xxl-12">
            <div class="row widget-grid">
              <!-- <OrderWelcomeCard /> -->
              <div class="col-xxl-4 col-sm-12 box-col-12">
                <div class="card profile-box">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="greeting-user">
                          <h4 class="f-w-600 mb-0">สรุปคำสั่งซื้อ</h4>
                          <p>{{ this.eventDetails.title }}</p>
                          <!-- <p>{{ this.eventDetails.event_date }},{{ this.eventDetails.province }}</p> -->

                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div class="cartoon">
                      <img
                        class="img-fluid"
                        src="@/assets/images/dashboard/cartoon.svg"
                        alt="vector women with leptop"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- <OrderPurchaseSaleCard /> -->
              <div class="col-xl-4 col-sm-6 box-col-6">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="card widget-1">
                      <div class="card-body">
                        <div class="widget-content">
                          <div class="widget-round secondary">
                            <div class="bg-round">
                              <svg class="svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#cart`
                                  "
                                ></use>
                              </svg>
                              <svg class="half-circle svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#halfcircle`
                                  "
                                ></use>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <h4>{{ this.total_order }}</h4>
                            <span class="f-light"> คำสั่งซื้อ </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card widget-1">
                      <div class="card-body">
                        <div class="widget-content">
                          <div class="widget-round primary">
                            <div class="bg-round">
                              <svg class="svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#income`
                                  "
                                ></use>
                              </svg>
                              <svg class="half-circle svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#halfcircle`
                                  "
                                ></use>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <h4>{{ this.total_image }}</h4>
                            <span class="f-light"> จำนวนภาพที่ขาย </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-sm-6 box-col-6">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="card widget-1">
                      <div class="card-body">
                        <div class="widget-content">
                          <div class="widget-round success">
                            <div class="bg-round">
                              <svg class="svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#tag`
                                  "
                                ></use>
                              </svg>
                              <svg class="half-circle svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#halfcircle`
                                  "
                                ></use>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <h4>{{ this.total_amt_vat }}</h4>
                            <span class="f-light">
                              รายได้รวม หลังหักภาษีมูลค่าเพิ่ม({{ this.vat }}%)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card widget-1">
                      <div class="card-body">
                        <div class="widget-content">
                          <div class="widget-round success">
                            <div class="bg-round">
                              <svg class="svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#customers`
                                  "
                                ></use>
                              </svg>
                              <svg class="half-circle svg-fill">
                                <use
                                  :xlink:href="
                                    require('@/assets/svg/icon-sprite.svg') +
                                    `#halfcircle`
                                  "
                                ></use>
                              </svg>
                            </div>
                          </div>
                          <div>
                            <h4>{{ this.p_share_vat }}</h4>
                            <span class="f-light">
                              ส่วนแบ่งทีม({{ this.photographer_share }}%,{{
                                this.number_of_photographer
                              }}ท่าน, ≈{{
                                (
                                  this.p_share_vat / this.number_of_photographer
                                ).toFixed(2)
                              }})
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <AllCampaignsCard /> -->

      <div v-if="this.is_event_permission === 'Y'">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0">คำสั่งซื้อของทีม</h5>
                <button
                  type="button"
                  @click.prevent="loadOrderSummery()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
              <p style="text-align: left; font-size: xx-small">
                <ui>
                  <li v-if="this.is_mhao === 'Y'">
                    special condition คือออเดอร์ที่สร้างโดยระบบเพิ่มเติม
                    อาทิเช่น <br />&nbsp;&nbsp;&nbsp;&nbsp; > นักวิ่งเหมาแล้ว
                    และส่งรูปเพิ่มเติมให้นักวิ่ง หรือ ช่างภาพ upload
                    เข้ามาในระบบหลังจากนักวิ่งเหมาไปแล้ว
                    และนักวิ่งแจ้งขอรูปเพิ่มเติม <br />&nbsp;&nbsp;&nbsp;&nbsp;
                    > ออเดอร์พิเศษให้กับผู้จัดกิจกรรม
                    ตามเงือนไขที่ตกลงกับผู้จัดแต่ละกิจกรรม(ถ้ามี)
                  </li>
                </ui>
              </p>
            </div>
            <div class="table-responsive" v-if="this.is_mhao === 'Y'">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="text-align: center">คำสั่งซื้อ</th>
                    <th style="text-align: center">จำนวนรูป</th>
                    <th style="text-align: center">ยอดขาย</th>
                    <!-- <th style="text-align: center">รูปภาพ</th> -->
                    <th style="text-align: center">{{ this.is_free_download_keep_contract==='Y'?"วันที่สร้างออร์เดอร์":"วันที่ชำระเงิน" }}</th>
                    <th style="text-align: center">หมายเหตุ</th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding === 'N'">
                  <tr v-for="order in this.orders" :key="order">
                    <td style="text-align: left">
                      <b
                        ><u>
                          <a
                            :href="
                              this.$route.params.q +
                              '/view/' +
                              order.confirm_key +
                              order.id
                            "
                            target="_blank"
                            >{{ order.confirm_key }}{{ order.id }}</a
                          >
                        </u></b
                      >
                    </td>
                    <td style="text-align: center">
                      {{ order.image_length }}
                    </td>
                    <td style="text-align: center">
                      {{ order.amount }}
                    </td>

                    <td style="text-align: center">
                      {{ order.payment_date }}
                    </td>
                    <td style="text-align: left">
                      {{ 
                        this.is_free_download_keep_contract==='Y'?
                        "Free":
                        order.amount === 0
                          ? "[special condition]"
                          : order.is_mhao === "Y"
                          ? "แพ็กเกจ เหมา"
                          : "-"
                      }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.loadding === 'Y'">
                  <tr>
                    <td colspan="5">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>&nbsp;</p>
              <div class="d-flex justify-content-center">
                <paginate
                  v-if="this.total_order > this.init_page_size"
                  v-model="page"
                  :page-count="this.page_tab"
                  :page-range="3"
                  :margin-pages="2"
                  :clickHandler="clickCallback"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                >
                </paginate>
              </div>
            </div>

            <p>&nbsp;</p>

            <div
              class="col-xl-12"
              v-if="this.is_photographer_view_report === 'Y'"
            >
              <div class="card">
                <div class="card-header">
                  <div class="header-top">
                    <h5 class="m-0"><b>สรุปข้อมูลช่างภาพ</b></h5>
                    <button
                      type="button"
                      @click.prevent="loadOrderImageByUser()"
                      class="btn btn-primary"
                    >
                      <vue-feather type="refresh-cw" size="18"> </vue-feather>
                      Refresh
                    </button>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th style="text-align: center"><b>ลำดับ</b></th>
                        <th style="text-align: center"><b>รหัสช่างภาพ</b></th>
                        <th style="text-align: left"><b>ชื่อ</b></th>
                        <th style="text-align: center">
                          <b>จำนวนที่อัปโหลด</b>
                        </th>
                        <th style="text-align: center"><b>จำนวนยอดขาย</b></th>
                        <th style="text-align: center">
                          <b>จำนวนยอดขาย(%)</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="this.loadding_order_image_by_user === 'Y'">
                      <tr>
                        <td colspan="6">
                          <div class="d-flex justify-content-center">
                            <img
                              style="align-items: center; width: 60px"
                              src="../../assets/images/running_loading.svg"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      v-if="
                        this.data_order_image_by_user.length === 0 &&
                        this.loadding_order_image_by_user === 'N'
                      "
                    >
                      <tr>
                        <td colspan="6">
                          <div class="d-flex justify-content-center">
                            ไม่พบข้อมูล
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="this.data_order_image_by_user.length !== 0">
                      <tr
                        v-for="(item, index) in this.data_order_image_by_user"
                        :key="item"
                      >
                        <td scope="row" style="text-align: center">
                          {{ index + 1 }}
                        </td>
                        <td scope="row" style="text-align: center">
                          {{ item.user_id_text }}
                        </td>
                        <td style="text-align: left">
                          {{ item.display_name }}
                        </td>
                        <td style="text-align: center">
                          {{ item.image_upload_count }}
                        </td>
                        <td style="text-align: center">
                          {{ item.image_sale_count }}
                        </td>
                        <td style="text-align: center">
                          {{ Number(item.percen).toFixed(2) }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div
              class="col-xl-12"
              v-if="this.is_photographer_view_report === 'Y'"
            >
              <div class="card">
                <div class="card-header">
                  <div class="header-top">
                    <h5 class="m-0"><b>ข้อมูลสรุปคะแนนช่างภาพ</b></h5>
                    <br />

                    <button
                      v-if="this.is_photographer_view_report === 'Y'"
                      type="button"
                      @click.prevent="reportPerformance()"
                      class="btn btn-primary"
                    >
                      <vue-feather type="refresh-cw" size="18"> </vue-feather>
                      Refresh
                    </button>
                  </div>
                  <p style="text-align: left; font-size: xx-small">
                    <ui>
                      <li>มีรูปที่ขายได้ใน Order เหมา นับ 1 คะแนน</li>
                      <li>มีรูปที่ขายได้ใน Order แยก นับรูปละ 1 คะแนน</li>
                    </ui>
                  </p>
                </div>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th style="text-align: center"><b>ลำดับ</b></th>
                        <th style="text-align: center"><b>รหัสช่างภาพ</b></th>
                        <th style="text-align: left"><b>ช่างภาพ</b></th>
                        <th style="text-align: center"><b>ออเดอร์แยก</b></th>
                        <th style="text-align: center"><b>ออเดอร์เหมา</b></th>
                        <th style="text-align: center"><b>รวมคะแนน</b></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.loadding_report_performance === 'Y'">
                      <tr>
                        <td colspan="6">
                          <div class="d-flex justify-content-center">
                            <img
                              style="align-items: center; width: 60px"
                              src="../../assets/images/running_loading.svg"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      v-if="
                        this.data_report_performance.length === 0 &&
                        this.loadding_report_performance === 'N'
                      "
                    >
                      <tr>
                        <td colspan="6">
                          <div class="d-flex justify-content-center">
                            ไม่พบข้อมูล
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="this.data_report_performance.length !== 0">
                      <tr
                        v-for="(item, index) in this.data_report_performance"
                        :key="item"
                      >
                        <td scope="row" style="text-align: center">
                          {{ index + 1 }}
                        </td>
                        <td scope="row" style="text-align: center">
                          {{ item.user_id_text }}
                        </td>
                        <td style="text-align: left">
                          {{ item.display_name }}
                        </td>
                        <td style="text-align: center">
                          {{ item.score - item.mhao_score }}
                        </td>
                        <td style="text-align: center">
                          {{ item.mhao_score }}
                        </td>
                        <td style="text-align: center">
                          {{ item.score }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div
              class="col-xl-12"
              v-if="this.is_photographer_view_report === 'Y'"
            >
              <div class="card">
                <div class="card-header">
                  <div class="header-top">
                    <h5 class="m-0">
                      <b>ข้อมูลสรุปยอดขายตามจำนวนภาพของออเดอร์</b>
                    </h5>
                    <br />

                    <button
                      type="button"
                      @click.prevent="reportOrderSummery()"
                      class="btn btn-primary"
                    >
                      <vue-feather type="refresh-cw" size="18"> </vue-feather>
                      Refresh
                    </button>
                  </div>
                  <p style="text-align: left; font-size: xx-small">
                    <ui>
                      <li>ยอดขายแบ่งตามจำนวนภาพภายในออเดอร์</li>
                      <li v-if="this.is_mhao === 'Y'">
                        special condition คือออเดอร์ที่สร้างโดยระบบเพิ่มเติม
                        อาทิเช่น <br />&nbsp;&nbsp;&nbsp;&nbsp; >
                        นักวิ่งเหมาแล้ว และส่งรูปเพิ่มเติมให้นักวิ่ง หรือ
                        ช่างภาพ upload เข้ามาในระบบหลังจากนักวิ่งเหมาไปแล้ว
                        ระบบสร้างออเดอร์เพื่อส่งรูปเพิ่มเติมให้กับนักวิ่ง
                        <br />&nbsp;&nbsp;&nbsp;&nbsp; >
                        ออเดอร์พิเศษให้กับผู้จัดกิจกรรม
                        ตามเงือนไขที่ตกลงกับผู้จัดแต่ละกิจกรรม(ถ้ามี)
                      </li>
                    </ui>
                  </p>
                </div>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th style="text-align: center"><b>ลำดับ</b></th>
                        <th style="text-align: center"><b>ราคาขาย</b></th>
                        <th style="text-align: center"><b>จำนวนออเดอร์</b></th>
                        <th style="text-align: center"><b>จำนวนรูป</b></th>
                        <th style="text-align: center"><b>ยอดขาย</b></th>
                        <th style="text-align: center"><b>หมายเหตุ</b></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.loadding_report_order_summery === 'Y'">
                      <tr>
                        <td colspan="6">
                          <div class="d-flex justify-content-center">
                            <img
                              style="align-items: center; width: 60px"
                              src="../../assets/images/running_loading.svg"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      v-if="
                        this.data_report_order_summery.length === 0 &&
                        this.loadding_report_order_summery === 'N'
                      "
                    >
                      <tr>
                        <td colspan="6">
                          <div class="d-flex justify-content-center">
                            ไม่พบข้อมูล
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="this.data_report_order_summery.length !== 0">
                      <tr
                        v-for="(item, index) in this.data_report_order_summery"
                        :key="item"
                      >
                        <td scope="row" style="text-align: center">
                          {{ index + 1 }}
                        </td>
                        <td scope="row" style="text-align: center">
                          {{ item.amount }}
                        </td>
                        <td style="text-align: center">
                          {{ item.order_count }}
                        </td>
                        <td style="text-align: center">
                          {{
                            item.is_mhao === "Y"
                              ? "&infin;"
                              : item.amount === this.mhao_price &&
                                item.is_mhao === "N"
                              ? "&infin;"
                              : item.image_length
                          }}
                        </td>

                        <td style="text-align: center">
                          {{ item.amount * item.order_count }}
                        </td>
                        <td
                          scope="row"
                          style="text-align: left; font-size: xx-small"
                        >
                          {{
                            this.is_free_download_keep_contract==='Y'?
                              "Free":item.amount === 0
                              ? "[special condition]"
                              : item.amount === this.mhao_price &&
                                item.is_mhao === "Y"
                              ? "แพ็กเกจ เหมา"
                              : item.amount === this.mhao_price &&
                                item.is_mhao === "N"
                              ? "เลือกรูปเกินราคาเหมา"
                              : "-"
                          }}
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          &nbsp;
        </div>
      </div>
      <div v-if="this.is_event_permission === 'CHECKING'">
        <div class="d-flex justify-content-center">authorized checking...</div>
      </div>
      <div v-if="this.is_event_permission === 'N'">
        <div class="d-flex justify-content-center">
          this page is not authorized
        </div>

        <div v-if="this.is_mhao !== 'Y' && this.loadding === 'N'">
          <div class="d-flex justify-content-center">event is not currect!</div>
        </div>
      </div>
    </div>
  </div>
  <!-- this.is_event_permission {{ this.is_event_permission }}
  this.loadding:{{ this.loadding }}
  this.is_mhao:{{ this.is_mhao }} -->
</template>
<style scoped>
.table-fit {
  width: 1px;
}
</style>
<script>
import orderDetail from "./orderDetail.vue";
//import OrderWelcomeCard from "./orderWelcomeCard.vue";
import VuejsPaginateNext from "../../components/Paginate.vue";
import axios from "axios";
export default {
  name: "order",
  components: {
    orderDetail,
    paginate: VuejsPaginateNext,
  },
  setup() {},
  data() {
    return {
      eventDetails: {},
      orders: [],
      is_mhao: "Y",
      mhao_price: 10000,
      order_count: 0,
      page_tab: 0,
      loadding: "N",
      page: 1,
      init_page_size: 20,

      total_order: null,
      total_amt: null,
      p_share: null,
      total_image: null,
      photographer_share: null,
      number_of_photographer: null,

      loadding_order_image_by_user: "N",
      data_order_image_by_user: [],

      loadding_report_performance: "N",
      data_report_performance: [],

      loadding_report_order_summery: "N",
      data_report_order_summery: [],

      is_photographer_view_report: "N",

      is_event_permission: "CHECKING",

      vat: null,
      total_amt_vat: null,
      p_share_vat: null,
      token: localStorage.getItem("jwt")
    };
  },
  async created() {
    const res = await axios.get("/v1/events/" + this.$route.params.q);
    //console.log("res.data", JSON.stringify(res.data));
    if (res.data.status === "success") {
      this.eventDetails = res.data;
      this.eventPackage = res.data.package;

      this.mhao_price = res.data.mhao_price;
      return res.data;
    } else {
      alert("[EventDetail]พบข้อผิดพลาด!");
      this.$router.push({ path: "/" });
    }
  },
  async mounted() {
    //this.user = this.user_store.user.body;

    //check permesion
    let _is_event_permission = await this.event_permission();
    //console.log("_is_event_permission:"+_is_event_permission)
    if (_is_event_permission === "Y") {
      this.loadOrderSummery();
    }
  },
  methods: {
    async event_permission() {
      try {
        let data = {
          event_key: this.$route.params.q,
        };
        const headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("jwt")
        };
        const response = await axios.post("v1/order/event-permission", data, {
          headers
        });
        //console.log(JSON.stringify(response))
        if (response.data.status === "success") {
          this.is_event_permission = response.data.body.event_permission;
          return response.data.body.event_permission;
        } else {
          alert("การตรวจสอบสิทธิ์ พบข้อผิดพลาด! ");
          return "N";
        }
      } catch (error) {
        alert("การตรวจสอบสิทธิ์ พบข้อผิดพลาด กรุณาแจ้งผู้ดูแลระบบ");
        return "N";
      }
    },
    async loadOrderSummery() {
      try {
        this.loadding = "Y";
        this.total_order = 0;
        this.total_image = 0;
        this.total_amt_vat = 0;
        this.p_share_vat = 0;
        const headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      };
        const response_summery = await axios.get(
          "/v1/order/order-summery/" + this.$route.params.q,
          {
            headers
          }
        );

        if (response_summery.data.is_mhao === "Y") {
          this.total_order = response_summery.data.body.total_order;
          this.total_amt = response_summery.data.body.total_amt;
          this.p_share = response_summery.data.body.p_share;
          this.total_image = response_summery.data.body.total_image;
          this.photographer_share =
            response_summery.data.body.photographer_share;
          this.number_of_photographer =
            response_summery.data.body.number_of_photographer;
          this.is_photographer_view_report =
            response_summery.data.body.is_photographer_view_report;
          this.vat = response_summery.data.body.vat;
          this.total_amt_vat = response_summery.data.body.total_amt_vat;
          this.p_share_vat = response_summery.data.body.p_share_vat;
          this.is_free_download_keep_contract = response_summery.data.is_free_download_keep_contract;
          // set for detail pagination //
          this.order_count = this.total_order;
          this.page_tab = Math.round(
            this.total_order / this.init_page_size + 0.5
          );
          //----------------//
          this.loadOrder();
        }
      } catch (error) {
        alert("พบข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ", error);
        location.reload();
      }
    },
    async loadOrder() {
      this.loadding = "Y";
      let data = {
        page: 1,
        page_size: this.init_page_size,
        order_count: this.order_count,
        page_tab: this.page_tab,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "/v1/order/order-paid-pagination/" + this.$route.params.q,
        data,
        {
          headers
        }
      );
      //console.log("response"+JSON.stringify(response));
      if (response.data.status === "success") {
        this.orders = response.data.body;
        this.is_mhao = response.data.is_mhao;
        this.loadding = "N";
      } else {
        alert("Load order พบข้อผิดพลาด!");
        this.loadding = "N";
      }
    },
    async clickCallback(pageNum) {
      let data = {
        page: pageNum,
        page_size: this.init_page_size,
        order_count: this.order_count,
        page_tab: this.page_tab,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "/v1/order/order-paid-pagination/" + this.$route.params.q,
        data,
        {
          headers
        }
      );
      this.orders = response.data.body;
    },
    async reportPerformance() {
      this.loadding_report_performance = "Y";
      this.data_report_performance = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/dashboard-report-performance",
        data,
        {
          headers
        }
      );

      if (response.data.status === "success") {
        this.data_report_performance = response.data.body;

        this.loadding_report_performance = "N";
      } else {
        alert("reportPerformance พบข้อผิดพลาด! ");
      }
    },
    async loadOrderImageByUser() {
      this.loadding_order_image_by_user = "Y";
      this.data_order_image_by_user = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/running-order-image-by-user",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        this.data_order_image_by_user = response.data.body;

        this.loadding_order_image_by_user = "N";
      } else {
        alert("Load order พบข้อผิดพลาด!");
      }
    },
    async reportOrderSummery() {
      this.loadding_report_order_summery = "Y";
      this.data_report_order_summery = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const response = await axios.post(
        "v1/admin/dashboard-report-order-summery",
        data
      );

      if (response.data.status === "success") {
        this.data_report_order_summery = response.data.body;

        this.loadding_report_order_summery = "N";
      } else {
        alert("reportOrderSummery พบข้อผิดพลาด! ");
      }
    },
  },
};
</script>
